.service-section-wrapper {
    margin-top: 7rem;
}
.S-heading {
    font-size: clamp(2rem, 5vw, 4rem);
    color: #4c4c4c;
    font-weight: bold;
}
.service-background-image-container {
    width: 100%;
    height: 170vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    overflow: hidden;
}
.S-Bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.service-section-top p {
    text-align: center;
    max-width: 600px !important;
}
.service-section-top h1 {
    max-width: 900px !important;
}
.service-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.service-section-info {
    width: 290px;
    min-height: 350px;
    background-color: #f3f2f0;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    color: #505050;
    margin: 1rem 2rem;
}
.service-section-info h2 {
    margin: 1rem 0rem;
}
.service-section-bottom {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.service-section-info p {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: 600;
}