* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Sans-serif;
}
.home-container {
    position: flex;
    text-align: center;
    color: white;
    overflow: hidden;
    min-height: 100vh;
    text-align: center;
    width: 85vw;
    max-width: 1900px;
    margin: 0rem auto;

}
.home-banner-container{
    padding-top: 0rem;
}
.home-bannerImage-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -2; 
    overflow: hidden;
}
.Bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.first-heading {
    margin-top: 300px;
    margin-left: 7.2rem;
    font-weight: bold;
    font-size: clamp(2rem, 5vw, 4rem);
    color: #4c4c4c;
    max-width: 700px;
}
.second-heading {
    font-size: clamp(2rem, 5vw, 4rem);
    font-weight: bold;
    color: #4c4c4c;
    max-width: 700px;
    margin-top: 0;
    margin-left: 1.87rem;
}
.primary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 500px;
    color: #0000009d;
    margin: 1.2rem 6rem;
    
    
}
.secondary-button {
    text-decoration: none;
    padding: 1rem 2.5rem;
    background-color: #fe9e0d;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14rem;
    
}
.secondary-button svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
}
.secondary-button:hover {
    background-color: #e48f0f;
}





