.contact-page-wrapper {
    margin: 0rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
}
.contact-background-image-container {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -2;
    overflow: hidden;
}
.C-Bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contact-form-container {
    background-color: #EDE9E8;
    max-width: 390px;
    width: 100%;
    margin-top: 3rem;
    margin-right: 8rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 40px;
    border-radius: 5rem;
    transition: border 0.1s;
}
.contact-form-container .input-control {
    background-color: #EDE9E8;
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
}
.contact-form-container::placeholder {
    color: #97979c;
    transition: transform 0.1s;
}
.contact-form-container.valid-email {
    border: 3px solid #1882f8;
}
.contact-form-container.invalid-email {
    border: 3px solid #f81818;
}

.contact-form-container .input-control:active::placeholder,
.contact-form-container .input-control:focus::placeholder,
.contact-form-container .input-control:valid::placeholder {
    transform: translateX(5px);
}
.input-control::placeholder {
    font-size: 1.3rem;
}
.fill-color {
    color: #b5b5bb;
}
.check-color {
    color: #1882f8;
}
.success-color {
    color: #00da1d;
}
.error-color {
    color: #f81818;
}
.text-message{
    margin-top: 0.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
    margin-right: 8rem;
    
}
.text-message.valid-email {
    color: #00da1d;
}
.text-message.invalid-email {
    color: #f81818;
}
.primary-he {
    margin-top: 220px;
    font-size: clamp(2rem, 5vw, 4rem);
    color: white;
    max-width: 900px;
    font-weight: bold;
}
.primary-h {
    margin-top: 175px;
    font-size: clamp(2rem, 5vw, 4rem);
    color: white;
    max-width: 900px;
    font-weight: bold;
}

.s-button {
    padding: 1rem 2.5rem;
    background-color: #fe9e0d;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    position: absolute;
    align-items: center;
    justify-content: center;
    margin-left: 25.9rem;
    bottom: 19rem;
}

.s-button:hover {
    background-color: #e48f0f;
}