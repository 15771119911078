.detail{
    margin-top: 10vh;
    margin-left: 27%;
    height: 10vh;
    width: 90vh;
    font-size: 35px;
    font-weight: 400px;
    justify-items: left;
    text-align: left;
}

.title1{
    margin-top:1vh;
    margin-left: 2vh;
    width: 40vh;
    height: 5vh;
    border: 1px solid black;
    font-size: 25px;
    padding: 8px;
    border-radius: 5px;
    color: black;
}
.title1:hover{
    background-color:rgb(211, 208, 208);
}


.title1 a:link {
    text-decoration: none;
    color: black;
}
  
.title1 a:visited {
    text-decoration: none;
    color: black;
}
  
.title1 a:hover {
    text-decoration: none;
    color: black;
}
  
.title1 a:active {
    text-decoration: none;
    color: black;
 }

