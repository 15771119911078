.first-nav{
    border: none;
    width: 100%;
    height: 16vh;
    display: flex;
    gap: 10px;
}

.first-nav-logo img{
    height: 13vh;
    width: 12vh;
    margin-top: 1.5vh;
    margin-left: 20vh;
}

.first-nav-title .p1{
    font-size: 8vh;
    font-weight: bolder;
    margin-top: 1.4vh;
    color: rgb(243,148,30);
}

.first-nav-title1 .p2{
    font-size: 3.2vh;
    font-style: italic;
    margin-left: 10vh;
    color: rgb(175, 170, 170);
}

.second-nav{
    border: none;
    background-color: rgb(87, 85, 85);
    width: 100%;
    height: 5vh;
    display: flex;
    place-content: center;
}

.second-nav-links{
    flex: 1;
    text-align: right;
}

.second-nav-links ul li{
    list-style: none;
    display: inline-block;
    margin-top: 1.3vh;
}
  
.second-nav-links ul li a{
    color: #fff;
    text-decoration: none;
    margin-right: 15vh;
    font-size: 20px;
}

.details{
    margin-top: 5vh;
    margin-left: 29.3rem;
}

.title input{
    height: 5vh;
    width: 90vh;
    text-indent: 2.5vh;
}

.description textarea{
    height: 20vh;
    width: 90vh;
    resize: none;
}


.addcourse-row{
    display: flex;
}

.Forms ::placeholder{
    font-size: 3vh;
}

.addcourse-col{
    margin-left: 25%;
    margin-top: 5vh;
    border: 1px solid rgb(175, 170, 170);
    height: 10rem;
    width: 90vh;
}

.Forms .form-input1{
    height: 50px;
    width: 90vh;
    border: none;
    background-color:rgb(175, 170, 170);
    font-size: 30px;
    text-indent: 2vh;
    text-align: justify, center;
}

.butts .add{
    margin-top: 15.5vh;
    margin-left: 2.5vh;
    height:5vh;
    width: 17.2vh;
    font-size: 2.5vh;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.butts .add:hover{
    background-color:rgb(133, 131, 131);
}

.addfile{
    display: flex;
    gap: 5vh
    ;
}

.callname{
    border: 1px solid rgb(133, 131, 131);
    margin-left: 5vh;
    height: 4vh;
    width: 50vh;
    text-align: justify, center;
}

.addfile .inputfiles input{
    margin-left: 5vh;
    padding: 3vh;
    height: 4vh;
    width: 50vh;
}