.createpagestudent{
  width: 100%;
  height: 100vh;
  border: 1px solid white;
} 

.container2{
     align-items: center;
     display:flow-root;
     width: 42.3vh;
     height: 70vh;
     margin: 200px auto;
     margin-top: 15vh;
     border-radius: 20px;
     place-items: center;
     outline: 5px;
     box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  }

  .container2 h1{
    margin-top: 3vh;
    text-align: center;
    width: 43vh;
    color: #420D09;
    font-size: 45px;
  }
  
  .createinput{
    margin-top: 20px;
    margin-left: 30px;
  }
  
  .createinput input{
    width: 347px;
    height: 55px;
    background: rgb(234, 234, 234);
    border: 1px solid rgb(224, 225, 225);
    border-radius: 1px solid #fff;
    outline: none;
    color: black;
    border-radius: 5px;
    padding: 6px;
    font-size: 19px;
    text-indent: 3vh;
  }
  
  ::placeholder{
    color: rgb(115, 120, 121);
    text-justify: auto;
    font-size: 19px;
  }
  
  .signupbutton button{
    width: 350px;
    height: 45px;
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: 10px;
    padding: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #420D09;
    color: #E0CDA9;
    border-radius: 100px;
    font-size: 18px;
  }
  
  .signupbutton button:hover{
    background-color: #CFB595;
    border: none;
    color: #412920; 
  }
  
  .hrefs p{
    text-align: center;
    width: 43vh;
    font-size: 19px;
    font-weight: lighter;
    color: black;
  }
  
  .createinput img{
    width: 21px;
    height: 20px;
    position: absolute;
    margin-top: 16px;
    margin-left: 1vh;
  }

  .row1{
    display: flex;
    gap: 5.3vh;
    margin: auto;
    margin-bottom: 2px;
  }
  .row1 .datelabel p{
    color:rgb(115, 120, 121);
    margin-top: 12px;
    margin-left: 4vh;
  }

  .row1 .genderlabel p{
    color:rgb(115, 120, 121);
    margin-top: 12px;
    margin-left: 4vh;
  }
  
  .label-row{
    display: flex;
    gap: 5vh;
  }
  .label-row .student-datelabel p{
    color:rgb(115, 120, 121);
    margin-top: 12px;
    margin-left: 3.8vh;
  }

  .label-row .student-genderlabel p{
    color:rgb(115, 120, 121);
    margin-top: 12px;
    margin-left: 4vh;
  }

  .row{
    display: flex;
    gap: 17px;
    margin: auto;
  }
  
  .row .date input{
    background: rgb(234, 234, 234);
    border: 1px solid rgb(224, 225, 225);
    border-radius: 5px;
    font-size: 19px;
    margin-left: 3.2vh;
    width: 17vh;
    height: 5vh;
    text-align: center;
    padding: 3px;
  }

  .row .Gender select{
    background: rgb(234, 234, 234);
    border: 1px solid rgb(224, 225, 225);
    border-radius: 5px;
    font-size: 19px;
    width: 17vh;
    height: 5vh;
    text-align: center;
    padding: 3px;
  }
