*{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.teacherhomepage{
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;   
  background-image: url('../Assets/Cour-Cert\ User\ Interface\ Background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.app-logo1{
  flex-basis: 10%;
  flex: 1;
  margin: 0 10px;
}

.app-logo1 img{
  width: 150px;
}

.nav-links1{
  flex: 1;
  text-align: right;
  margin: 0 10px;
}

.nav-links1 ul li{
  list-style: none;
  display: inline-block;
  margin: 0 20px;
}

.nav-links1 ul li a{
  color: #fff;
  text-decoration: none;
}

.searchBar{
  flex: 1;
  display: flex;
}

#search-input{
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
}

#search-button{
  background-color: orange;
  color: #fff;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

#search-button:hover{
  background-color: #0055b3;
}

.addCourse {
  padding-left: 200px;
  padding-top: 600px;
}

.addCourse button{
  background-color: orange;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
}

.addCourse button:hover {
  background-color: #0056b3;
}

Dialog{
  height: 20vh;
  width: 20vh;
}