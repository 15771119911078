.navHomepage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 30px;
    background-color: rgba(0,0,0,0.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 80px;
    border-radius: 100px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    color: #fff;
}