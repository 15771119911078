.profilepage{
    width: 100%;
    height: 100vh;
    background-color: rgb(245,246,250);
    border:1px solid rgb(245,246,250);
    
  }

.row-1{
    height: 45rem;
    width: 100%;
    display: flex;
    margin-top: 15vh;
    gap: 10vh;
    background-color: rgb(245,246,250);
    border:1px solid rgb(245,246,250);
    place-content: center;
}

.prof-container{
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    height: 40rem;
    width: 25rem;
}

.user-avatar img{
    margin-top: 5vh;
    margin-left: 21%;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 50%;
    height: 15rem;
    width: 15rem;
}

h1{
    margin-top: 10vh;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
} 

.about1 {
    text-align: auto;
    border: 1px solid rgb(206, 206, 206);
    box-shadow: 0 5px 15px rgba(196, 194, 194, 0.25);
    width: 30vh;
    height: 15vh;
    margin-top: 2vh;
    margin-left: 13.2%; 
    border-radius: 10px;
}

.info-container{
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    height: 40rem;
    width: 50rem;
}

.label0 p{
    font-size: 3vh;
    color: rgb(0, 123, 255);
    margin-top: 4vh;
    margin-left: 40px;
    margin-bottom: 20px;
}

.info-container input{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    width: 35vh;
    height: 3.8vh;
    
}

::placeholder{
    font-size: 16px;
}
.col-1{
    margin-left: 40px;
    display: flex;
    gap: 18.2rem;
}

.col-1 p{
    color: black;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.col-2{
    margin-left: 40px;
    display: flex;
    gap: 2.5rem;
}

.col-3{
    margin-top: 20px;
    margin-left: 40px;
    display: flex;
}
.col-3 p{
    color: black;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.col-4{
    margin-left: 40px;
    display: flex;
}

.col-5{
    margin-top: 20px;
    margin-left: 40px;
    display: flex;
    gap: 18.7rem;
}

.col-5 p{
    color: black;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.col-6{
    margin-left: 40px;
    display: flex;
    gap: 2.5rem;
}


.col-7{
    display: flex;
    gap: 20px;
    margin-top: 22vh;
    place-content: center;
}

.but1 button{
    width: 13vh;
    height: 4.2vh;
    color: white;
    background: rgb(108, 117, 125);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    font-size: 1.2rem;
}

.but1 button:hover{
    color: white;
    background: rgb(90, 98, 104);
}

.but2 button{
    width: 13vh;
    height: 4.2vh;
    color: white;
    background: rgb(0, 125, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    font-size: 1.2rem;
}

.but2 button:hover{
    color: white;
    background: rgb(0, 105, 217);
}


/* .col-7{
    margin-top: 22vh;
    place-content: center;
    display: flex;
    width: 10vh;
    height: 4vh;
    padding: 5px;
    gap: 20px;
}
.col-7 .but1 button{
    font-size: 1rem;
    cursor: pointer;
    color: #CFB595;
    border: none;
    background-color: #412920; 
}

.col-7 .but1 button:hover{
    background-color: #CFB595;
    border: none;
    color: #412920; 


} */