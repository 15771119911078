.More-section-Wrapper {
    position: flex;
    justify-content: space-between;
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    text-align: center;
    width: 85vw;
    max-width: 1900px;
    margin: 0rem auto;
}
.first-header {
    margin-top: 8rem;
    font-size: 4rem;
    color: #4c4c4c;
    font-weight: bold;
}
.MoreDetails-background-image-container {
    height: 205vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    overflow: hidden;
}

.Bg1 {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.section-position {
    display: grid;
    margin-top: 5rem;
    margin-right: 80rem;
    grid-template-columns: repeat(2,auto);
    grid-gap: 120px;
    align-items: center;
    
}
.section-content {
    width: 700px;
    position: flex;
    color:  #e8e4c9;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
    padding: 1rem 2rem;
    border-radius: 1rem;
    text-align: justify;
}
.title {
    font-size: 1.9rem;
    width: 500px !important;
    text-align: justify;
}
.description {
    margin-top: 2rem;
    font-size: 1.2rem;
    
}

.pic1 {
    height: 43vh;
    width: 33vw;
    border-radius: 50%;
}

.pic2 {
    height: 45vh;
    width: 35vw;
    border-radius: 50%;
}
.pic3 {
    height: 45vh;
    width: 35vw;
    border-radius: 50%;
}

