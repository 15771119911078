.containers{
  border: 1px solid white;
  height: 100vh;
}

.container1{
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 200px;
  width: 600px;
  /* background: rgb(247,249,250); */
  padding-bottom: 30px;
  border: 1px solid rgb(247,249,250); ;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}

.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  width: 100%;
  margin-top: 30px;
}

.text {
  color: #9d3c00;
  font-size: 48px;
  font-weight: 700;
}

.underline{
  width: 61px;
  height: 6px;
  background: #9d3c00;
  border-radius: 9px;
}

.inputs{
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.input1{
  display: flex;
  align-items: center;
  margin: auto;
  width: 480px;
  height: 80px;
  background: #eaeaea;
  border-radius: 6px;
}

.input1 img{
  margin: 0px 30px;
}

.input1 input{
  height: 50px;
  width: 400px;
  background: transparent;
  border: none;
  outline: none;
  color: #797979;
  font-size: 19px;
}

.input1 .password-viewer img{
  height: 24px;
  width: 24px;
}

.input1 .password-icon img{
  height: 21px;
  width: 19px;
}

.input1 .email-icon img{
  height: 16px;
  width: 21px;
}

.password-viewer{

  cursor: pointer;
}
/* 
.submit-container{
  display: flex;
  gap: 30px;
  margin: 60px auto;
  margin-bottom: -50px;
}

.submit{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 59px;
  color: #fff;
  background: #4c00b4;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;
} */

/* .gray{
  background: #EAEAEA;
  color: #676767;
} */

 /* Add or update styles for user type selection buttons  */
.user-type {
display: flex;
gap: 10px;
margin: auto;
}

.user-type-button {
background: #eaeaea;
border: none;
border-radius: 6px;
padding: 20px 45px;
cursor: pointer;
font-size: 19px;
}

.user-type-button.active {
background: #9d3c00;
color: #fff;
}

.user-type-button.active:hover {
  background: red;
  color: #fff;
}

 /* ... (other styles remain unchanged)  */
.forgot-password{
padding-left: 62px;
margin-top: 30px;
color: #797979;
font-size: 18px;
margin-bottom: 50px;
}

.forgot-password span{
color: #4500a6;
cursor: pointer;
}

.create-account{
  display: flex;
  flex-direction: column;
  align-items: center;
margin-top: -30px;
color: #797979;
font-size: 15px;
margin-bottom: 50px;
}

.error-message{
  padding-left: 62px;
  margin-top: -10px;
  color: #ba0000;
  font-size: 18px;
  margin-bottom: 50px;
}

.create-account p a{
  color: default;
  
}