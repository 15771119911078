*{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.studenthomepage{
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;   
  background-image: url('../Assets/Cour-Cert\ User\ Interface\ Background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

nav .navStudentHomepage{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 30px;
    background-color: rgba(0,0,0,0.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 80px;
    border-radius: 100px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    color: #fff;
}

.app-logo1{
  flex-basis: 10%;
  flex: 1;
  margin: 0 10px;
}

.app-logo1 img{
  width: 200px;
  height: auto;
}

.searchBar1{
  flex: 1;
  display: flex;
  margin: 0 10px;
}

#search-input1{
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
}

#search-button1{
  background-color: orange;
  color: #fff;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

#search-button1:hover{
  background-color: #0055b3;
}

.nav-link2{
  display: flex;
  gap: 10px;
  text-align: right;
  margin: 0 10px;
}

.nav-link2 ul li{
  list-style: none;
  display: inline-block;
  margin: 0 20px;
}

.nav-link2 ul li a{
  color: #fff;
  text-decoration: none;
}

.nav-link2 ul li a:hover{
  padding: 7px;
  border-radius: 5px;
  background: rgba(80, 80, 238);
  color: #fff;
  text-decoration: none;
}

div .addcoursebutton{
  border-radius: 10px;
  background-color: white;
  height: 20vh;
  width: 40vh;
  margin-top: 10vh;
  margin-left: 50vh;
}

.addcoursebutton button{
margin-left: 13vh;
margin-top: 2.5vh;
border-radius: 50%;
height: 15vh;
width: 15vh;
background-image: url(addlogo.png);
background-size: 15vh, 15vh;
background-repeat: no-repeat;
border-radius: 50%;
cursor: pointer;
}

.addcoursebutton button:hover {
border-radius: 50%;
background-image: url(addlogo2.png);
background-size: 15vh, 15vh;
background-repeat: no-repeat;
}
